<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">New User</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="6">
          <base-input label="Name"
                      name="name"
                      placeholder="Name"
                      v-model="model.name" required>
          </base-input>
        </b-col>
        <b-col md="6">
          <base-input label="Email/Username"
                      name="email"
                      placeholder="Email/Username"
                      v-model="model.email" required>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Role"
                      name="Role"
                      v-model="model.role_id">
            <select class="form-control" v-model="model.role_id">
              <option Selected disabled value="x">-- Please Select --</option>
              <option v-for="item in roles" :key="item.id" v-bind:value="item.id">{{ item.name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="6" v-if="model.role_id == 12">
          <base-input label="Contractor"
                      name="Contractor"
                      v-model="model.contractor_id">
            <select class="form-control" v-model="model.contractor_id">
              <option Selected disabled value="x">-- Please Select --</option>
              <option v-for="item in contractors" :key="item.contractor_id" v-bind:value="item.contractor_id">{{ item.contractor_name }}</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Password"
                      name="password"
                      type="password"
                      placeholder="Password"
                      v-model="model.password" required>
          </base-input>
        </b-col>
        <b-col md="6">
          <base-input label="Password Confirmation"
                      name="password_confirmation"
                      type="password"
                      placeholder="Password Confirmation"
                      v-model="model.password_confirmation" required>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <br>



      <base-button type="primary" @click="createData()">Create New User</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
              <i>
                1. Password must be at least 10 characters in length.
                </i>
                <br>
                <i>
                2. Password must contain at least one lowercase letter. [a-z].
                </i>
                <br>
                <i>
                3. Password must contain at least one uppercase letter. [A-Z].
                </i>
                <br>
                <i>
                4. Password must contain at least one number. [0-9].
                </i>
                <br>
                <i>
                5. Password  must contain a special character from the list. [@$!%*#?&].
                </i>
                <br>
                <i>
                6. Password cannot have empty space.
                </i>
        </h5>
      </div>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate();

      axios.get(apiDomain + 'lookup/role_list', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.roles = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Role Listing")
      } );

      axios.get(apiDomain + 'setup/contractor', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.contractors = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Contractor Listing")
      } );
    },
    data() {
      return {
        model: {
          name: null,
          email: null,
          role_id: 'x',
          contractor_id: 'x',
          user: null,
          active_status: 1,
        },
        abc: '100',
        roles: [],
        contractors: [],
      }
    },
    methods: {
      createData(){
        if ( !this.model.email )
        {
          this.$notify({
            message:
              '<b>Invalid Input : All Column are Mandatory in Create New User </b> - These field are required.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else if ( this.model.role_id && this.model.role_id == 12 && !this.model.contractor_id)
        {
          this.$notify({
            message:
              '<b>Contractor field is required.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          axios.post(apiDomain + 'setup/user', this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New User: ' + this.model.email + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Create New User")
          });
        }
      },
    }
  }
</script>
<style>
</style>

<template>
  <section>
    <div class="content">
      <base-header class="pb-6">
        <b-row align-v="center" class="py-4">
          <b-col cols="7" lg="6">
            <h6 class="h2 text-white d-inline-block mb-0">
              Project Requirements Management
            </h6>
            <nav
              aria-label="breadcrumb"
              class="d-none d-md-inline-block ml-md-4"
            >
            </nav>
          </b-col>
        </b-row>
      </base-header>
      <b-container fluid class="mt--6">
        <div>
          <card
            class="card-border-diagonal p-0"
            style="border: 1px solid black;"
          >
            <b-row class="my--4">
              <b-col md="2">
                <!-- empty for side "jump to div"
                <br><br><br><br><br><br><br><br><br><br> -->
              </b-col>

              <b-col md="8" class="bg-custom">
                <h3 class="mt-4">
                  Create New Projects
                </h3>
                <card class="card-border-inner bg-transparent form-custom-css">
                  <b-row>
                    <b-col md="3">
                      Select the available records
                    </b-col>

                    <b-col md="2">
                      <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="showPrmRecordsTable"
                        >Browse</base-button
                      >
                    </b-col>
                  </b-row>

                  <b-row v-if="selectedSites.length > 0">
                    <b-col>
                      <div class="form-row">
                        <b-col md="12">
                          <base-input label="Project Name"
                                      name="Project Name"
                                      v-model="model.project_name">
                          </base-input>
                        </b-col>
                      </div>

                      <div class="form-row">
                        <b-col md="12">
                          <base-input
                            label="Developer"
                            name="Developer"
                            v-model="model.developer_id"
                          >
                            <select class="form-control" v-model="model.developer_id">
                              <option Selected disabled value="x">
                                -- Please Select A Developer --
                              </option>
                              <option
                                v-for="item in developers"
                                :key="item.developer_id"
                                v-bind:value="item.developer_id"
                              >
                                {{ item.developer_code }} - {{ item.developer_name }}
                              </option>
                            </select>
                          </base-input>
                        </b-col>
                      </div>                     

                      <div class="form-row">
                        <b-col md="12">
                           <base-input
                              label="Project Type"
                              name="Project Type"
                              v-model="model.project_type_id"
                            >
                            <select class="form-control" v-model="model.project_type_id">
                              <option Selected disabled value="x">
                                -- Please Select A Type --
                              </option>
                              <option
                                v-for="item in project_type"
                                :key="item.project_type_id"
                                v-bind:value="item.project_type_id"
                              >
                                {{ item.project_type }}
                              </option>
                            </select>
                          </base-input>
                        </b-col>
                      </div>

                      <div class="form-row">
                        <b-col md="12">
                          <base-input label="PPA Counter Party"
                                      name="PPA Counter Party"
                                      v-model="model.ppa_counter_party">
                          </base-input>
                        </b-col>
                      </div>

                      <div class="form-row">
                        <b-col md="12">
                          <base-input label="PPA Sign Date"
                                      name="PPA Sign Date"
                                      type="date"
                                      v-model="model.ppa_sign_date">
                          </base-input>
                        </b-col>
                      </div>

                      <div class="form-row">
                        <b-col md="12">
                          <base-input label="PPA Total Capacity kWp"
                                      name="PPA Total Capacity kWp"
                                      v-model="model.ppa_total_capacity_kwp">
                          </base-input>
                        </b-col>
                      </div>

                      <div class="form-row">
                        <b-col md="12">
                          <base-input label="Watt Class"
                                      name="Watt Class"
                                      v-model="model.watt_class">
                          </base-input>
                        </b-col>
                      </div>
                    </b-col>
                  </b-row>

                  <b-row v-if="selectedSites.length > 0">
                    <b-col>
                      <h3>Site</h3>

                      <b-row>
                        <b-col>
                          <b-table
                          sticky-header="1000px"
                          striped
                          :items="selectedSites"
                          :fields="columnSelectedSites">
                          </b-table>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>

                  <div style="float: right;" v-if="selectedSites.length > 0">
                    <base-button size="md" class="p-2" style="background-color: #C00000;" @click="$router.back()">Cancel</base-button>
                    <base-button size="md" class="p-2" style="background-color: #375623;" @click="createData()">Next</base-button>
                  </div>

                  <!-- <b-row>
                    <b-col>
                      selectedItems: {{ selectedItems }}<br>
                      selectedSites: {{ selectedSites }}
                    </b-col>
                  </b-row> -->
                </card>
              </b-col>

              <b-col md="2">
                <!-- empty for side key contents -->
              </b-col>
            </b-row>
          </card>
        </div>

        <!-- <div>prm_records: {{ prm_records }}</div> -->
      </b-container
      >
    </div>

    <modal
      :show.sync="isPrmRecordsTableVisible"
      size="xl"
      body-classes="p-0"
      title="PRM Records">
        <card>
        <!-- <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"> -->
          <!-- <div class="text-center"> -->
            <!-- selectedItems: {{ selectedItems }} -->
            <b-table
            sticky-header="1000px"
            striped
            :items="prm_records"
            :fields="column">
                <template #cell(checkbox)="row">
                    <b-form-checkbox v-model="row.selected" @input="updateSelectedItems(row)"></b-form-checkbox>
                </template>
            </b-table>
          <!-- </div> -->

          <div style="float: right;">
            <base-button size="md" class="p-2" style="background-color: #C00000;" @click="closePrmRecordsTable">Cancel</base-button>
            <base-button size="md" class="p-2" style="background-color: #375623;" @click="mapSelectedItemsToSelectedSites">Next</base-button>
          </div>
        </card>
    </modal>
  </section>
</template>
<script>
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";

export default {
  mixins: [ErrorFunctionMixin],
  components: {
  },
  created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    this.getPrmRecordsListing();
    this.getDeveloperListing();
    this.getProjectTypeListing();
    this.getProjectSiteStatusListing();
  },
  data() {
    return {
        prm_records: [],
        isPrmRecordsTableVisible: null,
        column: [
            {
                key: "checkbox",
                label: "",
            },
            // {
            //     key: "prm_record_import_id",
            //     // sortable: true,
            //     label: "prm_record_import_id",
            // },
            // {
            //     key: "prm_no",
            //     // sortable: true,
            //     label: "prm_no",
            // },
            // {
            //     key: "prm_entry_date",
            //     // sortable: true,
            //     label: "prm_entry_date",
            // },
            {
                key: "prm_customers",
                // sortable: true,
                label: "Customers",
                thClass: "max-width-column-header",
                tdClass: "max-width-column-data",
            },
            // {
            //     key: "prm_business_development",
            //     // sortable: true,
            //     label: "prm_business_development",
            // },
            {
                key: "prm_signed_capacity_mwp",
                // sortable: true,
                label: "Signed Capacity (MWp)",
            },
            // {
            //     key: "prm_est_rec_ownership",
            //     // sortable: true,
            //     label: "prm_est_rec_ownership",
            // },
            // {
            //     key: "prm_est_rec_capacity",
            //     // sortable: true,
            //     label: "prm_est_rec_capacity",
            // },
            // {
            //     key: "prm_sc_export_ownership",
            //     // sortable: true,
            //     label: "prm_sc_export_ownership",
            // },
            // {
            //     key: "prm_smallie_epc_partner",
            //     // sortable: true,
            //     label: "prm_smallie_epc_partner",
            // },
            // {
            //     key: "prm_module_watt_class",
            //     // sortable: true,
            //     label: "prm_module_watt_class",
            // },
            // {
            //     key: "prm_module_price_assumption",
            //     // sortable: true,
            //     label: "prm_module_price_assumption",
            // },
            // {
            //     key: "prm_ppa_status",
            //     // sortable: true,
            //     label: "prm_ppa_status",
            // },
            // {
            //     key: "prm_ppa_sign_month",
            //     // sortable: true,
            //     label: "prm_ppa_sign_month",
            // },
            {
                key: "prm_ppa_actual_signed_date",
                // sortable: true,
                label: "PPA Signed Date",
            },
            // {
            //     key: "prm_ppa_epc_model",
            //     // sortable: true,
            //     label: "prm_ppa_epc_model",
            // },
            // {
            //     key: "prm_specified_site_handover_month",
            //     // sortable: true,
            //     label: "prm_specified_site_handover_month",
            // },
            // {
            //     key: "prm_roof_repair_reroofing",
            //     // sortable: true,
            //     label: "prm_roof_repair_reroofing",
            // },
            // {
            //     key: "prm_owner_of_repair_works",
            //     // sortable: true,
            //     label: "prm_owner_of_repair_works",
            // },
            // {
            //     key: "prm_engineer",
            //     // sortable: true,
            //     label: "prm_engineer",
            // },
            // {
            //     key: "prm_project_engineer",
            //     // sortable: true,
            //     label: "prm_project_engineer",
            // },
            {
                key: "prm_address",
                // sortable: true,
                label: "Address",
                thClass: "max-width-column-header",
                tdClass: "max-width-column-data",
            },
            {
                key: "prm_postal_code",
                // sortable: true,
                label: "Postal Code",
            },
            // {
            //     key: "prm_pelim_site_survey",
            //     // sortable: true,
            //     label: "prm_pelim_site_survey",
            // },
            // {
            //     key: "prm_engineering_drawing_boq_itb_status",
            //     // sortable: true,
            //     label: "prm_engineering_drawing_boq_itb_status",
            // },
            // {
            //     key: "prm_special_requests",
            //     // sortable: true,
            //     label: "prm_special_requests",
            // },
            // {
            //     key: "prm_water_sprinkler_fire_rated_roof",
            //     // sortable: true,
            //     label: "prm_water_sprinkler_fire_rated_roof",
            // },
            // {
            //     key: "prm_customer_info_form_submitted",
            //     // sortable: true,
            //     label: "prm_customer_info_form_submitted",
            // },
        ],
        columnSelectedSites: [
          {
            key: "prm_customers",
            label: "Site Name",
          },
          {
            key: "prm_address",
            label: "Address",
          },
          {
            key: "prm_postal_code",
            label: "Postal Code",
          },
          {
            key: "prm_signed_capacity_mwp",
            label: "Capacity (kWp)",
            formatter: function(value) {
              return value * 1000;
            },
          },
          {
            key: "site_status_id",
            label: "Status",
            formatter: (value) => {
              const project_site_status = this.site_status.find(item => item.status_id == value);
              return project_site_status ? project_site_status.status_code : null;
            },
          },
        ],
        selectedItems: [],
        selectedSites: [],
        model: {
          developer_id: "x",
          project_type_id: "x",
          project_name: null,
          ppa_counter_party: null,
          ppa_sign_date: null,
          ppa_total_capacity_kwp: null,
          watt_class: null,
          selectedSites: [],
        },
        developers: [],
        project_type: [],
        site_status: [],
    };
  },
  methods: {
    getPrmRecordsListing()
    {
      axios.get(apiDomain + 'project/prm_import_from_upload', { headers: getHeader() })
      .then ( response => {
      if (response.status === 200)
      {
          this.tableData = response.data.data
          this.prm_records = response.data.data
          this.fuseData()
      }
      })
      .catch ( error => {
      console.log(error.response.status)
      this.errorFunction(error, "PRM Records Information")
      });
    },
    showPrmRecordsTable()
    {
      this.isPrmRecordsTableVisible = true;
    },
    closePrmRecordsTable()
    {
      this.selectedItems = [];
      this.isPrmRecordsTableVisible = false;
    },
    mapSelectedItemsToSelectedSites()
    {
      // check if there are any selected items, if no prompt error instead
      this.selectedSites = this.prm_records.filter(item => this.selectedItems.includes(item.prm_record_import_id));
      this.selectedSites = this.selectedSites.map(item => ({
        ...item,
        site_status_id: '8' // TBD: new set of site statuses from Andrew
      }));
      // compute project name based on first selected record prm customers
      this.model.project_name = this.selectedSites[0].prm_customers;
      this.model.ppa_counter_party = this.selectedSites[0].prm_customers;
      this.model.ppa_sign_date = this.selectedSites[0].prm_ppa_actual_signed_date;
      this.model.ppa_total_capacity_kwp = this.selectedSites.reduce((accumulator, item) => accumulator + (item.prm_signed_capacity_mwp * 1000), 0);
      this.model.watt_class = this.selectedSites[0].prm_module_watt_class;
      this.selectedItems = [];      
      this.isPrmRecordsTableVisible = false;
    },
    getDeveloperListing() {
      axios
      .get(apiDomain + "setup/developer", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.developers = response.data.data;
          // default to sembcorp solar || developer_id = 1
          // this.model.developer_id = 1;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Developer Information");
      });
    },
    getProjectTypeListing() {
      axios
      .get(apiDomain + "setup/project_type", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.project_type = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Project Type Information");
      });
    },
    getProjectSiteStatusListing() {
      // axios.get(apiDomain + 'lookup/site_status', { headers: getHeader() })
      axios.get(apiDomain + 'lookup/site_status.singapore_v2', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.site_status = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Site Status Information")
      } );
    },
    updateSelectedItems(row) {
      if (row.selected) {
        this.selectedItems.push(row.item.prm_record_import_id);
      } else {
        const index = this.selectedItems.indexOf(row.item.prm_record_import_id);
        if (index !== -1) {
          this.selectedItems.splice(index, 1);
        }
      }
    },
    createData() {
      // if (
      //   !this.model.project_name ||
      //   this.model.developer_id == "x" ||
      //   this.model.status_id == "x" ||
      //   this.model.project_manager == "x" ||
      //   this.model.project_engineer == "x" ||
      //   this.model.project_safety == "x"
      // ) {
      //   if (!this.model.project_name) {
      //     this.redErrorNotify(
      //       "<b>Invalid Input : Project Name Column is a Mandatory in Project </b>"
      //     );
      //   } else if (this.model.developer_id == "x") {
      //     this.redErrorNotify(
      //       "<b>Invalid Input : Developer Column is a Mandatory in Project </b>"
      //     );
      //   } else if (this.model.status_id == "x") {
      //     this.redErrorNotify(
      //       "<b>Invalid Input : Project Status is a Mandatory in Project </b>"
      //     );
      //   } else if (this.model.project_manager == "x") {
      //     this.redErrorNotify(
      //       "<b>Invalid Input : Project Manager is a Mandatory in Project </b>"
      //     );
      //   } else if (this.model.project_engineer == "x") {
      //     this.redErrorNotify(
      //       "<b>Invalid Input : Project Engineer is a Mandatory in Project </b>"
      //     );
      //   } else if (this.model.project_safety == "x") {
      //     this.redErrorNotify(
      //       "<b>Invalid Input : Safety Officer is a Mandatory in Project </b>"
      //     );
      //   } else if (this.model.project_task_template_id == "x") {
      //     this.redErrorNotify(
      //       "<b>Invalid Input : Task Template is a Mandatory in Project </b>"
      //     );
      //   } else if (this.model.project_document_template_id == "x") {
      //     this.redErrorNotify(
      //       "<b>Invalid Input : Document Template is a Mandatory in Project </b>"
      //     );
      //   } else {
      //     this.redErrorNotify(
      //       "<b>Invalid Input : Please fill in all the Mandatory Column in Project </b>"
      //     );
      //   }
      // } else if (
      //   this.model.project_progress < 0 ||
      //   this.model.project_progress > 100
      // ) {
      //   this.redErrorNotify(
      //     "<b>Invalid Input : Project Progress </b> - Input Must be between 0 - 100"
      //   );
      // } else {
      //   if (this.model.project_document_template_id == "x") {
      //     this.model.project_document_template_id = null;
      //   }

      //   if (this.model.project_task_template_id == "x") {
      //     this.model.project_task_template_id = null;
      //   }

        this.model.selectedSites = this.selectedSites;

        axios
          .post(apiDomain + "project/prm_import_from_upload.create_new_project", this.model, {
            headers: getHeader(),
          })
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
                message:
                  "<b>New Project : " +
                  this.model.project_name +
                  "</b> - Successfully Created.",
                timeout: 10000,
                icon: "ni ni-bell-55",
                type: "default",
              });
              // this.$router.back();
              this.$router.push({path: '/projectDashboardV3/'+ response.data.data});
            }
          })
          .catch((error) => {
            console.log(error.response.data.errors);
            this.errorFunction(error, "Project Creation");
          });
      // }
    },
  },
};
</script>
<style>

.bg-custom {
  background-color: #EFF7EA;
}

.card-border-diagonal {
  /* border: 2px solid #BDDBA9; */
  border-radius: 100px 0 100px 0;
  /* margin: 20px; */
  padding: 10px;
  /* max-width: 300px; */
}

.card-border-inner {
  border: 1px solid #ADB5BD;
  border-radius: 50px 50px 50px 50px;
  /* margin: 20px; */
  padding: 10px;
  /* max-width: 300px; */
}

.form-custom-css .form-control {
  border: 2px solid #BDDBA9;
  border-radius: 0;
  color: black;
}

.max-width-column-header {
  width: 50%;
}

.max-width-column-data {
  width: 50%;
  /* word-wrap: break-word !important; */
  white-space: normal !important;
  /* max-width: 100px;
  word-wrap: break-word; */
  /* display: inline-block; */
}

</style>
